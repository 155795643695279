export default {
    colors: {
        background: '#ffffff',
        text: '#121212',
        primary: '#121212',
        secondary: '#e9e9e9',
        tertiary: '#f3f3f3',
        highlight: '#5b8bf7',
    },
    fonts: {
        body:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    },
    sizes: {
        maxWidth: '1050px',
        maxWidthCentered: '650px',
    },
    responsive: {
        small: '35em',
        medium: '50em',
        large: '70em',
    },
}
