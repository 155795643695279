import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { contentType } from "../components/TableOfContents";


export type TableOfContentPropsType = {
    content: contentType[]
}

export type TableOfContentStateType = {
    content: contentType[]
}

const initialState: TableOfContentStateType = {
    content: []
};


const mergeStateToDefaultState = ( newState: TableOfContentStateType ): TableOfContentStateType => {
    if ( !newState.content ) return { content: [] }
    return {
        content: newState.content
    }
}

const slice = createSlice( {
    name: "tableOfContentStateType",
    initialState,
    reducers: {
        setContent: ( state: TableOfContentStateType, action: PayloadAction<TableOfContentStateType> ) => {
            const newState = mergeStateToDefaultState( action.payload )
            return Object.assign( {}, state, newState )
        }
    }
} );

export default slice.reducer;

export const { setContent } = slice.actions;
