import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import tableOfContentReducer from "./tableOfContent";

const reducer = combineReducers( {
    tableOfContent: tableOfContentReducer,
} );

const store = configureStore( { reducer } );
export type RootState = ReturnType<typeof reducer>

export default store;
